import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    { path: '/', name: 'home', component: () => import('@/views/HomeView') }, 
    
    { path: '/page-1', name: 'page-1', component: () => import('@/views/PageOne') }, 
    { path: '/page-2', name: 'page-2', component: () => import('@/views/PageTwo') }, 
    { path: '/page-3', name: 'page-3', component: () => import('@/views/PageThree') }, 
    { path: '/page-4', name: 'page-4', component: () => import('@/views/PageFour') }, 
    { path: '/page-5', name: 'page-5', component: () => import('@/views/PageFive') }, 
    { path: '/page-6', name: 'page-6', component: () => import('@/views/PageSix') }, 
    { path: '/page-7', name: 'page-7', component: () => import('@/views/PageSeven') }, 
    { path: '/page-8', name: 'page-8', component: () => import('@/views/PageEight') }, 
    { path: '/page-9', name: 'page-9', component: () => import('@/views/PageNine') }, 
    { path: '/page-10', name: 'page-10', component: () => import('@/views/PageTen') }, 
    { path: '/page-11', name: 'page-11', component: () => import('@/views/FinalView') }, 
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router