<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@font-face {
  font-family: 'Frutiger';
  src: url('https://res.cloudinary.com/flashvolve/raw/upload/v1709843042/fonts/agrofel/Frutiger.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Frutiger';
  src: url('https://res.cloudinary.com/flashvolve/raw/upload/v1709843062/fonts/agrofel/Frutiger-bold.ttf');
  font-weight: bold;
  font-style: normal;
}

html, body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  font-family: 'Frutiger', sans-serif;
}

@media screen and (max-width: 1440px) {
  html, body {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 768px) {
  html, body {
    position: relative;
    height: 110vh;
    overflow-y: auto;
  }
}
</style>
